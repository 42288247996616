import React from 'react'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { StaticImage } from 'gatsby-plugin-image'
import getTicketURL from '../../utils/TicketURL'
import ColorButton from '../../components/Ui/ColorButton'

const Hebergement = () => {
  const metaTitle = 'Hébergement | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, hébergement, hôtel, camping, dormir'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader
        title="Hébergement"
      />
      <div className="container content pb-12">

        <iframe title="Vidéo hébergement" className="rounded-3xl" src="https://stay22.com/embed/63837bda1da5be0016e282fe" id="stay22-widget" width="100%" height="460" frameBorder="0"></iframe>

        <div>
          <h2>Groupe Facebook Le Camping citoyen du Festif!</h2>
          <p>Ce groupe sert à mettre en relation les festivalier·ères en quête d'hébergement (camping, motorisés) avec les citoyen·nes de Baie-Saint-Paul. On t’invite à y publier tes offres et demandes.</p>

          <h4>Pour les campeuses et campeurs:</h4>
          <ul className='pl-24 !font-openSans !font-regular'>
            <li>Tu es responsable de tes arrangements avec les hôtes : assure-toi de bien comprendre leurs conditions et de respecter leur terrain.</li>
            <li>Le Festif! n’intervient pas dans la gestion, la réservation ou les conditions d’hébergement.</li>
            <li>Respect et civisme sont de mise : nous comptons sur toi pour préserver l’esprit de partage et de collaboration qui rend cette expérience unique.</li>
          </ul>

          <h4>Pour les citoyennes et citoyens:</h4>
          <ul className='pl-24 !font-openSans !font-regular'>
            <li>Le Festif! n’intervient pas dans l’organisation du Camping citoyen : la mise à disposition d’un terrain est une initiative indépendante de ta part.</li>
            <li>L'accord conclus avec les festivalier·ères est sous ta responsabilité : nous t'encourageons à bien définir tes règles et conditions.</li>
            <li>Nous te recommandons de ne pas exiger de paiement en échange d’un espace de camping afin d’éviter toute contrainte légale.</li>
          </ul>

          <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
            <p><ColorButton href="https://www.facebook.com/groups/campingcitoyendufestif/" text="REJOINDRE LE GROUPE" className="my-4"/></p>
          </div>

        </div>

        <div>
          {/* Village SAIL à Maison Mère */}
          <div className="mt-12">
            <h2>Village SAIL à Maison Mère</h2>
              <div className='w-full flex justify-left'>
                <StaticImage src="../../images/partenaires/sail.png" alt="Logo de SAIL" className='max-w-[250px] max-h-[100px]'/>
              </div>

              <h4>Adresse:</h4>
              <p>63 rue Ambroise-Fafard</p>

              <h4>Accès:</h4>
              <p>Le Camping est à 2 minutes à pied du cœur du festival.</p>
              <p>Le site est situé à l’arrière de Maison-Mère, au 63 rue Ambroise-Fafard (bien vouloir suivre les indications).</p>

              <h4>Stationnement:</h4>
              <p>Le stationnement pour les véhicules n'est pas directement situé sur le site du camping, mais plutôt à quelques minutes de marche hors du site (suivez les indications). Il y aura tout de même un débarcadère au camping pour y déposer son matériel.</p>

              <h4>Heures d'ouverture :</h4>
              <p>Les arrivées sont possibles à compter du mercredi le 16 juillet de 14h00 à 22h.</p>
              <p>Du jeudi au samedi, les arrivées se font de 10h00 à 22h00.</p>
              <p>Les départs se font avant 12h pour les nuitées individuelles en tente du jeudi, vendredi et samedi et  le dimanche 20 juillet avant 15h00.</p>

              <div className='ml-9'>
                <h3>VAN</h3>
                <h4>Passeport van (nuitées non disponibles à l’unité)</h4>
                <p>Le tarif donne accès aux nuits du mercredi 16, jeudi 17, vendredi 18 et samedi 19 juillet.</p>
                <p>Les nuits non utilisées ne sont pas transférables à une autre personne, car une fois le véhicule installé sur le site, il sera impossible de le déplacer avant ton départ définitif (à moins d’une urgence).</p>
                <br/>
                <p>Il est à noter qu’il ne sera pas possible de choisir ton emplacement, ils seront attribués en ordre d’arrivée.</p>

                <h4>Ce qui est admis:</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Tous les véhicules de types vans, westfalias, VR, roulotte, tente-roulotte, motorisés de 20 pieds et moins;</p></li>
                    <li><p>Un maximum de 4 personnes par terrain (et une seule van);</p></li>
                    <li><p>Les tentes de toit sur un véhicule;</p></li>
                    <li><p>Dormir dans tout véhicule ou motorisé, n’excédant pas 20 pieds.</p></li>
                  </ul>
                </div>

                <h4>Ce qui n'est pas admis:</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les animaux (incluant les chiens);</p></li>
                    <li><p>Les véhicules motorisés excédents 20 pieds;</p></li>
                    <li><p>Plus de une van sur le même site;</p></li>
                    <li><p>Une tente placée à côté de la van;</p></li>
                  </ul>
                </div>

                <h4>Détails</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les terrains sont d’une dimension de 20 pieds x 10 pieds;</p></li>
                    <li><p>Les roulottes et tentes-roulottes de 20 pieds et moins sont acceptées. Toutefois, si ta roulotte est encore attachée à ton véhicule principal, celui-ci devra être déplacé vers le stationnement prévu à cet effet;</p></li>
                    <li><p>Le branchement électrique n’est pas possible sur le site;</p></li>
                    <li><p>Des roulottes de douches et des toilettes seront à la disposition des campeur·euses;</p></li>
                    <li><p>Des lavabos sont disponibles sur le site;</p></li>
                    <li><p>Tout au long de ton séjour et à ton départ, il est très important de laisser ton espace propre et de trier tes déchets de façon adéquate aux endroits prévus à cet effet;</p></li>
                    <li><p>Merci de respecter la quiétude du site et le matériel sur place;</p></li>
                    <li><p>Une fois le véhicule installé sur le site, il sera impossible de quitter avant ton départ définitif (à moins d’une urgence);</p></li>
                    <li><p>Tout règlement enfreint entraînera une expulsion immédiate.</p></li>
                  </ul>
                </div>
              </div>

              <div className='ml-9'>
                <h3>TENTES</h3>

                <h4>Deux options s’offrent à toi: le Passeport OU les nuitées disponibles à l’unité.</h4>
                <p><span className='underline'>Passeport tente:</span> le tarif donne accès aux nuits du mercredi 16, jeudi 17, vendredi 18 et samedi 19 juillet.</p>
                <p><span className='underline'>Billets unitaires:</span> Bien vouloir respecter la nuitée associée à ton billet. Contrôle sur place.</p>

                <h4>Ce qui est admis:</h4>
                <div className='ul-container'>Les départs se font avant 12h pour les nuitées individuelles en tente du jeudi, vendredi et samedi et  le dimanche 20 juillet avant 15h00.
                  <ul>
                    <li><p>Une tente par terrain</p></li>
                    <li><p>Les tentes de 10 pieds  x 10 pieds et moins (donc les tentes conçues pour 4 personnes et moins) ;</p></li>
                  </ul>
                </div>

                <h4>Ce qui n'est pas admis:</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les animaux (incluant les chiens);</p></li>
                    <li><p>Les tentes de plus de 10 pieds x 10 pieds (donc conçue pour plus de 4 personnes);</p></li>
                    <li><p>Plus de une tente sur le même site, ou une tente cuisine (vous devez acheter un 2e emplacement);</p></li>
                  </ul>
                </div>

                <h4>Détails:</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les terrains sont d’une dimension de 10 pieds x 10 pieds;</p></li>
                    <li><p>Le branchement électrique n’est pas possible sur le site;</p></li>
                    <li><p>Des roulottes de douches et des toilettes seront à la disposition des campeur·euses;</p></li>
                    <li><p>Des lavabos sont disponibles sur le site;</p></li>
                    <li><p>Tout au long de votre séjour et à votre départ, il est très important de laisser votre espace propre et de trier vos déchets de façon adéquate aux endroits prévus à cet effet;</p></li>
                    <li><p>Merci de respecter la quiétude du site et le matériel sur place;</p></li>
                    <li><p>Tout règlement enfreint entraînera une expulsion immédiate;</p></li>
                  </ul>
                </div>
              </div>

              <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
                <p className="mt-6"><ColorButton href={getTicketURL()} text="RÉSERVER" className="my-4"/></p>
              </div>
            </div>

            {/* CAMPING DU FLEUVE */}
            <div className="mt-12">
              <h2>Camping du Fleuve</h2>

              <div className='ml-9'>
                <h3>TENTES</h3>
                <h4>Deux options s’offrent à vous: le Passeport OU les nuitées disponibles à l’unité.</h4>
                <p><span className='underline'>Passeport tente:</span> le tarif donne accès aux nuits du mercredi 16, jeudi 17, vendredi 18 et samedi 19 juillet.</p>
                <p><span className='underline'>Billets unitaires:</span> Veuillez respecter la nuitée associée à votre billet. Contrôle sur place.</p>

                <h4>Heures d'ouverture</h4>
                <p>Du mercredi au samedi, les arrivées se font de 15h00 à 22h00.</p>
                <p>Les départs se font avant 12h pour les nuitées individuelles en tente du jeudi- vendredi et samedi  ET le dimanche 20 juillet avant 15h00.</p>

                <h4>Ce qui est admis:</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les tentes de 10 pieds x 10 pieds et moins (Les tentes conçues pour plus de 4 personnes sont donc hors dimensions);</p></li>
                    <li><p>Un maximum de 4 personnes par terrain (et une seule tente); </p></li>
                  </ul>
                </div>

                <h4>Ce qui n'est pas admis:</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les animaux (incluant les chiens);</p></li>
                    <li><p>Faire des feux (en raison de la proximité de terrains protégés);</p></li>
                    <li><p>Les tentes de plus de 10 pieds x 10 pieds (donc conçue pour plus de 4 personnes);</p></li>
                    <li><p>Plus de une tente sur le même site, ou une tente cuisine (vous devez acheter un 2e emplacement);</p></li>
                  </ul>
                </div>

                <h4>Détails:</h4>
                <div className='ul-container'>
                  <ul>
                    <li><p>Les terrains sont d’une dimension de 10 pieds x 10 pieds;</p></li>
                    <li><p>Les campeur·euses auront accès gratuitement à des douches au 63 rue Ambroise-Fafard, derrière les bâtiments de la Maison Mère. Il s'agit des blocs douche du camping Village Festif! de Maison Mère. Tu dois compter une dizaine de minutes de marche pour t'y rendre par le Chemin des Petites-Franciscaines;</p></li>
                    <li><p>Le branchement électrique n’est pas possible sur le site;</p></li>
                    <li><p>Des toilettes seront à la disposition des campeur·euses;</p></li>
                    <li><p>Un lavabo sera disponible sur le site;</p></li>
                    <li><p>Tout au long de ton séjour et à ton départ, il est très important de laisser ton espace propre et de trier tes déchets de façon adéquate aux endroits prévus à cet effet;</p></li>
                    <li><p>Merci de respecter la quiétude du site et le matériel sur place;</p></li>
                    <li><p>Tout règlement enfreint entraînera une expulsion immédiate;</p></li>
                  </ul>
                </div>

                <h4>Adresse:</h4>
                <p>212 rue Sainte-Anne</p>

                <h4>Accès:</h4>
                <p>À ton arrivée, dirige-toi au 212 rue Sainte-Anne pour aller y stationner ton véhicule. Le camping se situe à proximité, à côté du Pavillon Saint-Laurent (bien vouloir suivre les indications). Par la suite, nous recommandons de te déplacer à pied vers le festival, via le raccourci du Chemin des Petites Franciscaines (15 minutes à pied).</p>

                <h4>Stationnement:</h4>
                <p>Le stationnement pour les véhicules sera adjacent au site de camping, accessible par la rue Sainte-Anne (bien vouloir suivre les indications).</p>
              </div>

              <div className={`${(process.env.GATSBY_IS_BORNE === 'true' || process.env.GATSBY_IS_BORNE === true) ? 'hidden' : ''}`}>
                <p className="mt-6"><ColorButton href={getTicketURL()} text="RÉSERVER" className="my-4"/></p>
              </div>
            </div>

            <div className="w-full px-auto mx-auto">
              <h2>Merci à nos partenaires d'hébergement</h2>
              <div className="flex">
                <p className="flex flex-col items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.germainhotels.com/fr/hotel-et-spa-le-germain/charlevoix">
                    <StaticImage imgClassName='invert' src="../../images/partenaires/legermain.png" />
                  </a>
                </p>
                <p className="flex flex-col items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://hotelbaiestpaul.com">
                    <StaticImage imgClassName='invert' src="../../images/partenaires/hotel_bsp.png" />
                  </a>
                </p>
                <p className="flex flex-col items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://maisonotis.com">
                    <StaticImage imgClassName='invert' src="../../images/partenaires/maisonotis.png" />
                  </a>
                </p>
                <p className="flex flex-col items-center">
                  <a target="_blank" rel="noopener noreferrer" href="https://moteldescascades.com/">
                    <StaticImage imgClassName='invert' src="../../images/partenaires/mc.png" />
                  </a>
                </p>
              </div>
            </div>

          </div>
        </div>
      </Wolflayout>
  )
}

export default Hebergement
